//import { headroom } from "headroom";
//import AOS from "aos";
import "instant.page";
import "jquery.easing";
import Flickity from "flickity";
import TypeIt from "typeit";
import BezierEasing from "bezier-easing";

//import cursory from "cssplus";

/* -------------------------------------------------------------------------- */
/*                                  Headroom                                  */
/* -------------------------------------------------------------------------- */
// window.addEventListener("DOMContentLoaded", event => {
// 	headroom(document.querySelector("header.header"), {
// 		useStyle: false
// 	});
// });

/* -------------------------------------------------------------------------- */
/*                              LazySizes config                              */
/* -------------------------------------------------------------------------- */

window.lazySizesConfig = {
	loadMode: 3,
	expand: 1000
};

/**
 * Limita un número al rango min, max
 * @param {Number} value - Valor a procesar
 * @param {Number} min - Valor mínimo
 * @param {Number} max - Valor máximo
 * @return {Number} Valor acotado de 'value' entre 'min' y 'max'
 */
const clamp = (value, min, max) => {
	return Math.min(Math.max(value, min), max);
};

/**
 *
 * Smooth Scroll
 *
 *
 */

// window.addEventListener("DOMContentLoaded", () => {
// 	const smoothScrollParams = {
// 		duration: 1000,
// 		timingFunction: "cubic-bezier(0.23, 1, 0.32, 1)"
// 	};
// 	const smoothScrollEl = new SmoothScroll(
// 		".main-container",
// 		smoothScrollParams
// 	);
// });

jQuery(function($) {
	$(document).ready(function() {
		// $("body").niceScroll({
		// 	scrollspeed: 200,
		// 	nativeparentscrolling: false
		// });
	});

	var scrollHold = true;
	/*--------------------------
	 *
	 *  getQueryVariable(var)
	 *
	 *--------------------------*/
	function getQueryVariable(variable) {
		var query = window.location.search.substring(1);
		var vars = query.split("&");
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split("=");
			if (pair[0] == variable) {
				return pair[1];
			}
		}
		return false;
	}

	/*-------------
	 *
	 *  isMobile
	 *
	 *-------------*/

	var TOUCH = "ontouchstart" in window;

	var isMobile = window.innerWidth <= 1024;

	const checkMobile = () => {
		isMobile = window.innerWidth <= 1024;
	};
	window.addEventListener("resize", _.debounce(checkMobile, 20));

	var $cursor;

	/* -------------------------------------------------------------------------- */
	/*                                  Flickity                                  */
	/* -------------------------------------------------------------------------- */

	var $sliders = $(".scroller-slider:not(.is-empty)");

	$sliders.each(function() {
		var $dis = $(this);
		var $next = $dis.siblings(".slider-nav").find(".slider-next");
		var $prev = $dis.siblings(".slider-nav").find(".slider-prev");
		var $slider = new Flickity($dis[0], {
			// options
			lazyLoad: 1,
			contain: true,
			wrapAround: true,
			adaptiveHeight: false,
			cellSelector: ".slide",
			prevNextButtons: false
		});
		$next.on("click", () => {
			$slider.next(true);
		});
		$prev.on("click", () => {
			$slider.previous(true);
		});
	});

	/* -------------------------------------------------------------------------- */
	/*                                   Typeit                                   */
	/* -------------------------------------------------------------------------- */
	const initTypeit = () => {
		let i = 1;
		$(".is-typeit").each(function() {
			var $dis = $(this);
			var dis = this;
			$dis.attr("id", "typeit-" + i);
			var typeEl = document.getElementById("typeit-" + i);
			const type = new TypeIt("#typeit-" + i, {
				strings: $(typeEl).data("content"),
				waitUntilVisible: true,
				//startDelete: true,
				speed: 60,
				startDelay: 1000
			}).go();
			$(typeEl).on("click", function() {
				type.reset().go();
			});
			i++;
		});
	};
	initTypeit();

	/* -------------------------------------------------------------------------- */
	/*                                   Cursors                                  */
	/* -------------------------------------------------------------------------- */
	const cursor = document.getElementById("cursor");
	const cursorNext = document.querySelectorAll(".slider-next");
	const cursorPrev = document.querySelectorAll(".slider-prev");

	const handleMouse = e => {
		document.documentElement.style.setProperty("--cursorX", e.clientX);
		document.documentElement.style.setProperty("--cursorY", e.clientY);
	};
	const startMouse = () => {
		window.addEventListener("mousemove", handleMouse, false);
		$(".slider-next").on("mouseenter mouseleave", handleNext);
		$(".slider-prev").on("mouseenter mouseleave", handlePrev);
	};
	const handleNext = e => {
		cursor.classList = e.type == "mouseenter" ? "cursor-next" : "";
		let color = $(e.target).hasClass("color-black") ? "#000000" : "#ffffff";
		document.documentElement.style.setProperty("--cursorColor", color);
	};
	const handlePrev = e => {
		cursor.classList = e.type == "mouseenter" ? "cursor-prev" : "";
		let color = $(e.target).hasClass("color-black") ? "#000000" : "#ffffff";
		document.documentElement.style.setProperty("--cursorColor", color);
	};
	startMouse();

	/* -------------------------------------------------------------------------- */
	/*                                Contact form                                */
	/* -------------------------------------------------------------------------- */

	/**
	 *
	 * Valida un email dado
	 * @param {string} mail - Email
	 * @return {bool} - True o false
	 *
	 */
	const validateEmail = mail => {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
			return true;
		}
		return false;
	};

	// Check si hay un mail válido
	const ghostInput = $(".contact-ghost-input"),
		realInput = $("#acf-field_5e1cc9730d3d6"),
		ghostButton = $(".contact-ghost-button"),
		contactForm = $("#contact-form"),
		realSubmit = contactForm.find(".form-submit.button");

	if ($("#contact").length) {
		ghostButton.on("click", () => {
			realSubmit.trigger("click");
		});
		contactForm.on("submit", () => {
			return true;
		});

		// Button typeit
		if (ghostButton.length) {
			ghostInput.on("keyup", function() {
				realInput.val($(this).val());
				if (!$(this).val()) {
					ghostButton
						.addClass("is-disabled")
						.html(ghostButton.data("empty"));
				} else if (validateEmail($(this).val())) {
					ghostButton
						.removeClass("is-disabled")
						.html(ghostButton.data("valid"));
				} else {
					ghostButton
						.addClass("is-disabled")
						.html(ghostButton.data("invalid"));
				}
			});
		}

		// Remove parameters from url after submit
		if (getQueryVariable("f")) {
			window.history.replaceState(
				null,
				null,
				window.location.pathname + "#contact"
			);
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                          Horizontal Scroller  + Parallax                   */
	/* -------------------------------------------------------------------------- */

	$(".is-parallax").paroller();

	// Sólo en home
	if ($("body").hasClass("home")) {
		/* --------------------------------- Typeit --------------------------------- */
		/*
		Sure, we are a creative team able to deliver amazing solutions,
		build bulletproof strategies, and all that. But, instead of reading this chit-chat
		for the umpteenth time, take a look at what really counts: our work.
			*/
		if ($("#home-typeit").length) {
			const homeType = new TypeIt("#home-typeit", {
				waitUntilVisible: true,
				//startDelete: true,
				speed: 50,
				startDelay: 2500
				// Show scroll down
				// afterComplete: instance => {
				// 	if ($(".home-arrow").length) {
				// 		$(".home-arrow").addClass("is-done");
				// 	}
				// }
			})
				.type("Let's get to the point: ")
				.pause(600)
				.break()
				.type("we are a design studio.")
				.pause(800)
				.delete(48)
				.pause(300)
				.type(
					"Sure, we are a creative team able to deliver amazing solutions, build bulletproof strategies, and all that."
				)
				.break()
				.break()
				.pause(500)
				.type("But, instead of reading this shit-sh")
				.pause(600)
				.delete(7)
				.pause(300)
				.type(
					"chit-chat for the umpteenth time, take a look at what really counts: "
				)
				.pause(800)
				.type(
					"<a href='/#work' class='link-underline-revert'>our work.</a>"
				)
				.go();
		}

		/* -------------------------------- Parallax -------------------------------- */
		$("body").addClass("keep-header");
		$(".home-parallax-item").paroller();

		const $parallax1 = $(".home-parallax-1 .text-xl > div"),
			$parallax2 = $(".home-parallax-2 .text-xl");

		const parallaxScroll = e => {
			//if (!scrollHold) {
			//console.log("hold", scrollHold);
			let top1 = $parallax1[0].getBoundingClientRect().top,
				top2 = +$parallax2[0].getBoundingClientRect().top + 150;

			// Parallax 1
			if (top1 < 180) {
				let opacity1 = clamp(top1 / 180, 0, 1).toFixed(2);
				$parallax1[0].style.opacity = opacity1;
				//console.log("top", top1, "opa", opacity1);
			} else if (top1 >= 180) {
				$parallax1[0].style.opacity = 1;
			}
			// Parallax 2
			//console.log(top2);
			if (top2 < 500 && top2 > 300) {
				let calc = top2 / 500;
				let opacity2 = 1 - clamp(calc, 0, 1);
				$parallax2[0].style.opacity = opacity2.toFixed(2);
			} else if (top2 <= 300 && top2 >= 250) {
				let opacity2 = 1;
				$parallax2[0].style.opacity = opacity2.toFixed(2);
			} else if (top2 < 250) {
				let opacity2 = clamp(top2 / 250, 0, 1);
				$parallax2[0].style.opacity = opacity2.toFixed(2);
			} else if (top2 >= 500) {
				$parallax2[0].style.opacity = 0;
			}

			// Remove header lock
			if (top2 < -120) {
				$("body").removeClass("keep-header");
			} else if (!$("body").hasClass("keep-header")) {
				$("body").addClass("keep-header");
			}
		};
		// window.addEventListener("scroll", parallaxScroll);
		// if (TOUCH) {
		// 	document.body.addEventListener("touchmove", parallaxScroll);
		// }

		/* -------------------------------- Scroller -------------------------------- */

		// Vars iniciales
		const $hContainer = $(".horizontal-scroll");
		const $hScroller = $(".horizontal-scroll .scroller");
		var scrollerWidth;
		const easing = BezierEasing(0.0, 0.43, 1.0, 0.61);

		// Actualizar variables en window resize
		const updateResize = () => {
			scrollerWidth = $hScroller[0].scrollWidth - window.innerWidth;
			if (scrollerWidth) {
				let scrollerHeight = scrollerWidth - window.innerHeight / 2;
				$hContainer[0].style.height = `${scrollerHeight}px`;
			}
		};
		if (!TOUCH) {
			updateResize();
			window.addEventListener("resize", _.throttle(updateResize, 20));
		}

		// Función scroll
		var onscroll = e => {
			let top = $hContainer[0].getBoundingClientRect().top;
			if (top < window.innerHeight) {
				//console.log("-top", -top);
				let scrollfinal = window.innerHeight * 1.5 - top;
				//console.log("scrolleft", scrollfinal);
				let eas = scrollfinal / $("#work").innerHeight();
				//console.log("eas", eas);
				$hScroller[0].scrollLeft = scrollfinal * easing(eas);
			} else {
				$hScroller[0].scrollLeft = 0;
			}
		};
		// Evento scroll
		if (!TOUCH) {
			onscroll();
			window.addEventListener("scroll", onscroll);
		}

		// Cancelar scroll horizontal con mwheel
		const preventHorizontal = e => {
			if (e.deltaX != 0) {
				e.preventDefault();
			}
		};
		if (!TOUCH) {
			$hScroller[0].addEventListener("wheel", preventHorizontal, false);
		}
	}

	/* -------------------------------------------------------------------------- */
	/*                             Home projects hover                            */
	/* -------------------------------------------------------------------------- */
	var checkHovers = false;
	$(".scroller-content.has-hover").on("mouseenter mousemove", function(e) {
		var dis = this,
			$dis = $(this);

		// En mouseenter, creamos el evento de mouseout
		if (e.type == "mouseenter") {
			checkHovers = true;
			// Volver al estado inicial en mouseout
			const scrollerLeave = evt => {
				$dis.addClass("is-initial");
				checkHovers = false;
			};
			$dis.one("mouseleave", scrollerLeave);
		} else if (checkHovers) {
			// Remove initial class if it exists
			$dis.removeClass("is-initial");

			// Calculate relative mouse position
			let $main_img = $dis.find(".image-main"),
				mouseX = parseInt(e.pageX - $dis.offset().left);

			// Aplicar opacity en mousemove (rango 0 a 1)
			var perc = 1 - ((mouseX * 100) / 600) * 0.01;
			perc = clamp(perc.toFixed(2), 0, 1);

			if (mouseX > 0) {
				$main_img.css("opacity", perc);
			}
		}
	});

	/* -------------------------------------------------------------------------- */
	/*                             Projects info modal                            */
	/* -------------------------------------------------------------------------- */

	// Toggle info window
	const projectModalToggle = () => {
		$("body, html").toggleClass("info-is-open");
	};
	$("body").on("click", ".project-info-trigger", projectModalToggle);

	/* -------------------------------------------------------------------------- */
	/*                                Smooth Scroll                               */
	/* -------------------------------------------------------------------------- */
	const smoothScroll = new SmoothScroll('a[href*="#"]', {
		speed: 1500,
		easing: "easeInOutCubic"
	});
	var hash;

	// $(window).on("load", function() {

	// 	hash = location.hash;
	// 	console.log(hash);
	// 	if (hash) {
	// 		hash = hash.replace("#", "");
	// 		let scrollToNode = document.getElementById(hash);
	// 		console.log(scrollToNode);
	// 		if (scrollToNode) {
	// 			console.log(scrollToNode.offsetTop);
	// 			$("body, html").scrollTop(scrollToNode.offsetTop);
	// 			//window.scrollTo(0, scrollToNode.offsetTop);
	// 			//scrollHold = false;
	// 		}
	// 	}
	// });

	/* -------------------------------------------------------------------------- */
	/*                                Loader + AOS                                */
	/* -------------------------------------------------------------------------- */

	var ignore_unload = false;

	// Prevent if link has mailto
	$("a[href^=mailto], a[href^=tel]").on("click", function() {
		ignore_unload = true;
	});

	$(window).on("beforeunload", function(e) {
		if (!ignore_unload) {
			//$("#loader").fadeIn();
			//$('#loader')
			$("#loader").removeClass("is-loaded");
		}
		ignore_unload = false;
	});

	// Remove AOS attributes if mobile
	$(document).ready(function() {
		if (TOUCH) {
			//$("[data-aos]").removeAttr("data-aos");
		}
	});
	$(window).on("load", function() {
		if (!$("body").hasClass("wp-admin")) {
			setTimeout(function() {
				AOS.init({
					duration: 750,
					easing: "easeOutQuart",
					once: true,
					offset: TOUCH ? 10 : 120
				});
			}, 500);
		}
		setTimeout(function() {
			hash = location.hash;
			if (hash) {
				hash = hash.replace("#", "");
				let scrollToNode = document.getElementById(hash);
				if (scrollToNode) {
					window.scrollTo({
						top: scrollToNode.offsetTop,
						behavior: "auto"
					});
				}
			}

			// Remove preload-transitions class from body to re-enable transitions
			$("body").removeClass("preload-transitions");
			$("#loader").addClass("is-loaded");
		}, 0);
	});
});
